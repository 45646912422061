import image_default from '/assets/images/payment/bank-logos/default.svg'
import image_diger from '/assets/images/payment/bank-logos/diger.svg'
import image_akbank from '/assets/images/payment/bank-logos/akbank.svg'
import image_asyabank from '/assets/images/payment/bank-logos/asyabank.svg'
import image_denizbank from '/assets/images/payment/bank-logos/denizbank.svg'
import image_finans from '/assets/images/payment/bank-logos/finans.svg'
import image_garanti from '/assets/images/payment/bank-logos/garanti.svg'
import image_halkbank from '/assets/images/payment/bank-logos/halkbank.svg'
import image_hsbc from '/assets/images/payment/bank-logos/hsbc.svg'
import image_ing from '/assets/images/payment/bank-logos/ing.svg'
import image_isbank from '/assets/images/payment/bank-logos/isbank.svg'
import image_teb from '/assets/images/payment/bank-logos/teb.svg'
import image_vakifbank from '/assets/images/payment/bank-logos/vakifbank.svg'
import image_ykb from '/assets/images/payment/bank-logos/ykb.svg'
import image_ziraat from '/assets/images/payment/bank-logos/ziraat.svg'

const bankLogos = {
	'default': image_default,
	'diger': image_diger,
	'akbank': image_akbank,
	'asyabank': image_asyabank,
	'denizbank': image_denizbank,
	'finans': image_finans,
	'garanti': image_garanti,
	'halkbank': image_halkbank,
	'hsbc': image_hsbc,
	'ing': image_ing,
	'isbank': image_isbank,
	'teb': image_teb,
	'vakifbank': image_vakifbank,
	'ykb': image_ykb,
	'ziraat': image_ziraat,
	'ziraatbank': image_ziraat,
}

export default bankLogos;