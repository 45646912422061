import '/assets/styles/partials/btn.scss';

import { Fragment, forwardRef, useState, useEffect, useRef, useMemo } from 'react'

// Partials
import Link from '@wearetla/tla-essentials-tools/partials/link';

// Deps
import omit from 'lodash/omit'
import Icon from '@wearetla/tla-essentials-tools/partials/icon';

const useLoadingStatus = (loading) => {
	let loadingTimeout = useRef(false);

	const [active, setActive] = useState(loading);
	const [show, setShow] = useState(loading);

	useEffect(() => {
		if (loadingTimeout.current !== false) {
			clearTimeout(loadingTimeout.current);
			loadingTimeout.current = false;
		}

		if (loading) {
			setActive(true);
			loadingTimeout.current = setTimeout(function () {
				setShow(true);
			}, 30);
		}
		else {
			setShow(false);
			loadingTimeout.current = setTimeout(function () {
				setActive(false);
			}, 200);
		}
	}, [loading]);

	return [active, show];
}

const Btn = forwardRef((props, ref) => {
	const [status, setStatus] = useState(props.loading ? 'loading' : props.status);
	const [statusActive, statusShow] = useLoadingStatus(status);

	let btnProps = useMemo(() => {
		let btnProps = omit(props, [
			'className', 'wrapText', 'tag', 'icon', 'afterIcon', 'loading', 'status', 'children',
		]);

		btnProps.className = 'btn ' + props.className;

		if(!props.children && !props.afterIcon && props.icon) {
			btnProps.className += ' icon-only'
		}
	
		if (props.tag === "button") {
			btnProps.type = props.type;
		}

		if (ref) {
			btnProps.ref = ref;
		}

		// Status
		if (statusShow) { btnProps.className += ' show-status'; }

		if (status) { btnProps.className += ' status-' + status; }

		return btnProps;
	}, [props, ref, statusShow, status]);

	let Tag = props.tag;

	useEffect(() => {
		setStatus(props.loading ? 'loading' : props.status);
	}, [props.status, props.loading]);

	
	if (props.tag === 'link') {
		Tag = Link;
	}

	const WrapElem = props.wrapText ? 'span' : Fragment;
	let wrapProps = {};

	if (props.wrapText) {
		wrapProps.className = "btn-text";
	}

	return (
		<Tag {...btnProps}>
			{(statusActive) &&
				<span className="btn-statuswrap">
					{status === 'complete' ?
						<Icon className="btn-status" name="check" />
						:
						<Fragment>
							{status === 'loading' ?
								<Icon className="btn-status" name="spinner" />
								:
								<Icon className="btn-status" name={status} />
							}
						</Fragment>
					}
				</span>
			}
			<div className="btn-content">
				{props.icon &&
					<>
						{typeof props.icon === 'string' ?
							<Icon className={`btn-icon ${(props.children || props.afterIcon) ? ' pre' : ''}`} name={props.icon} />
							:
							<span className="btn-iconwrap">{props.icon}</span>
						}
					</>
				}
				<WrapElem {...wrapProps}>
					{props.children}
				</WrapElem>
				{props.afterIcon &&
					<>
						{typeof props.afterIcon === 'string' ?
							
							<Icon className="btn-icon post" name={props.afterIcon} />
							:
							<span className="btn-iconwrap">{props.afterIcon}</span>
						}
					</>
				}
			</div>
		</Tag>
	)
})

Btn.displayName = "Btn";

Btn.defaultProps = {
	className: '',
	tag: 'button',
	children: false,
	status: false,
	wrapText: false,
	icon: false,
	afterIcon: false,
	type: "button",
}

export default Btn