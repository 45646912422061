import '/assets/styles/pages/checkout.scss';
import '/assets/styles/pages/checkout-summary.scss';

import config from '/config';

// Deps
import { useState, useEffect, useRef } from 'react'

// Functions
import formatDate from '/functions/format-date'
import parseIsoCurrency from '/functions/parse-iso-currency';

// Partials
import Img from '@wearetla/tla-essentials-tools/partials/img';
import Btn from '/views/partials/btn'
import Loader from '/views/partials/loader'
import Pricebox from '/views/partials/pricebox'
import Productbox from '/views/partials/productbox'

// Services
import saleServices from '/services/sale'

// Context
import { useModals } from '@wearetla/tla-essentials-tools/utilities/modals';
import { useGlobalState } from '@wearetla/tla-essentials-tools/utilities/global-state';
import { useAnalytics, parseAnalyticsProduct } from '/utilities/analytics';
import { useParams } from '@wearetla/tla-essentials-tools/utilities/navigator'
import { useAuth } from '@wearetla/tla-essentials-tools/utilities/auth';

// Data
import bankLogos from '/data/bank-logos'

const CheckoutSummary = () => {
	const params = useParams('checkoutSummary');


	const { sendAnalyticsEvent, sendGtagEvent } = useAnalytics();
	const { openModal } = useModals();
	const { setError } = useGlobalState();
	const { isGuest } = useAuth();

	const saleID = parseInt(params?.saleID ?? '0');
	const saleCode = params?.saleCode;

	const _mounted = useRef(false);
	const [data, setData] = useState(false);

	useEffect(() => {
		_mounted.current = true;

		return () => {
			_mounted.current = false;
		}
	}, [])
	
	useEffect(() => {
		if(saleID && saleCode) {
			setData(false);
			window.localStorage?.removeItem(config.storageKeys.exchangeCard);
			const storedSaleID = window.localStorage?.getItem('checkout-sale-id');
			if(storedSaleID === saleID.toString()) {
				window.localStorage?.removeItem('checkout-sale-id');
				window.localStorage?.removeItem('checkout-sale-has-support-products');
			}
	
			saleServices.getSummary(saleID, saleCode).then((payload) => {
				if(_mounted.current) {
					let completedSaleIDs = window.localStorage?.getItem('completed-sale-ids');
					try {
						completedSaleIDs = completedSaleIDs === null ? [] : JSON.parse(completedSaleIDs);
					}
					catch(e) {
						completedSaleIDs = [];
					}
					setData(payload);
					if(!completedSaleIDs.includes(payload.sale.id)) {
						try {
							if(config.gtagID) {
								completedSaleIDs.push(payload.sale.id);
								window.localStorage?.setItem('completed-sale-ids', JSON.stringify(completedSaleIDs));
	
								sendGtagEvent({
									event: 'conversion',
									includeBasketContent: false,
									sendTo: 'AW-381727730/qdmTCO_574kCEPLngrYB',
									value: payload.summary.grand_total.original,
									params: {
										'transaction_id': payload.sale.sale_code,
									},
									products: payload.sale.products.map(product => (
										parseAnalyticsProduct(product)
									)),
								});
	
								sendAnalyticsEvent({
									event: 'purchase',
									includeBasketContent: false,
									value: payload.summary.grand_total.original,
									params: {
										'transaction_id': payload.sale.sale_code,
									},
									products: payload.sale.products.map(product => (
										parseAnalyticsProduct(product)
									)),
								});

								sendAnalyticsEvent({
									event: 'view_checkout_success',
									params: {
										transaction_content: {
											currency: parseIsoCurrency(payload.summary.grand_total.currency),
											order_id: payload.sale.sale_code,
											line_items: payload.sale.products.map((product => ({
												product: parseAnalyticsProduct(product),
												quantity: product.basket_quantity,
												subtotal: product.total_price?.original,
											}))),
											bank_name: payload.payment?.bank?.name ?? '',
											total: payload.summary.grand_total.original,
											shipping_cost: (payload.summary?.services ?? []).find(service => service.service_code === 'KARGO')?.price?.original
										}
									}
								});
	
								if(window.fbq) {
									window.fbq('track', 'Purchase', {
										currency: "TRY",
										value: payload.summary.grand_total.original
									});
								}
							}
						}
						catch(e) {
							console.warn(e);
						}
					}
				}
			}).catch(() => {
				setError(true);
			})
		}
	}, [saleID, saleCode])

	return (
		<main className="page checkout checkout-summary loader-container">
			<Loader loading={!data} message="Siparişiniz getiriliyor..." />
			{data !== false &&
				<div className="checkout-wrap wrapper">
					<section className="checkout-left full">
						<div className="summary-message">
							{/* eslint-disable-next-line no-irregular-whitespace */}
							<h1 className="message-title">Teşekkürler, <br />Siparişiniz Tamamlandı.</h1>

							<div className="message-links">
								<Btn
									className="links-link outline white"
									tag="link"
									href="home">
									Anasayfaya Dön
								</Btn>
								{!isGuest &&
									<Btn
										className="links-link outline white"
										tag="link"
										href="account.orders">
										Siparişlerime Git
									</Btn>
								}
							</div>
						</div>
						<div className="checkout-section summary-info">
							<strong className="section-title">Sipariş Bilgileri</strong>

							<div className="info-content">
								<ul className="content-data">
									<li className="data-section">
										<strong>Sipariş No:</strong>
										<span>{data.sale.id}</span>
									</li>
									<li className="data-section">
										<strong>Sipariş Durumu:</strong>
										<span>{data.sale.status_text}</span>
									</li>
									<li className="data-section">
										<strong>Sipariş Kodu:</strong>
										<span>{data.sale.sale_code}</span>
									</li>
									<li className="data-section">
										<strong>Sipariş Zamanı:</strong>
										<span>{formatDate(data.sale.createdate, 'd MMMM yyyy EEEE, HH:mm')}</span>
									</li>
									{(data.sale.client_comment && data.sale.client_comment.length > 0) &&
										<li className="data-section">
											<strong>Sipariş Notu:</strong>
											<span>"{data.sale.client_comment}"</span>
										</li>
									}
								</ul>

								<div className="content-bottom">
									<div className="bottom-section bottom-addresses">
										<strong className="section-title">Teslimat Bilgilerim</strong>

										<div className="section-content">
											{data.sale.fix_status === 0 ?
												<div className="addresses-address">
													<strong className="address-title">Mağazadan Teslimat: <span>{data.sale.store.name}</span></strong>
													<p className="address-info">
														Adres: {data.sale.store.address}
													</p>
													{data.sale.store.phone_numbers &&
														<p className="address-contact">{data.sale.store.phone_numbers.join(', ')}</p>
													}
												</div>
												:
												<div className="addresses-address">
													<strong className="address-title">Teslimat Adresi</strong>
													<p className="address-info">
														Adres: {data.delivery.delivery_address}, {data.delivery.quarter_name}, {data.delivery.district_name}/{data.delivery.city_name}
													</p>
													<p className="address-contact">{data.delivery.delivery_mobile_number}, {data.delivery.title}</p>
												</div>
											}

											<div className="addresses-address">
												<strong className="address-title">Fatura Adresi</strong>
												<p className="address-info">
													Adres: {data.invoice.invoice_address}, {data.invoice.quarter_name}, {data.invoice.district_name}/{data.invoice.city_name}
												</p>
												<p className="address-contact">
													{data.invoice.type === 2 && data.invoice.id_number !== '' &&
														<>{data.invoice.id_number}, </>
													}
													{data.invoice.type === 1 && data.invoice.id_number !== '' &&
														<>{data.invoice.invoice_tax_office} {data.invoice.invoice_tax_number}, </>
													}
													{data.invoice.title}</p>
											</div>
										</div>
									</div>

									<div className="bottom-section bottom-payment">
										<strong className="section-title">Ödeme Bilgilerim</strong>

										<div className="section-content white">
											<strong className="payment-title">{data.payment.name}</strong>
											{/* Credit Card Info */}
											{data.payment.card_masked !== null &&
												<div className="payment-info card">
												</div>
											}
											{!!(data.payment.card_masked && data.payment.bank_account) &&
												<div className="payment-info account">
													<Img
														className="info-image"
														lazy
														src={bankLogos[data.payment.bank_account.erp_name] ? bankLogos[data.payment.bank_account.erp_name] : bankLogos.default} />

													<span className="info-data">{data.payment.bank_account.iban}</span>

													<span className="info-detail">{data.payment.bank_account.bank_name} {data.payment.bank_account.branch_name} Şubesi</span>
												</div>
											}

											<ul className="payment-services">
												<li className="services-service">
													<strong>Ürün Toplamı</strong>
													<Pricebox
														price={data.summary.price.original_str}
														showCurrency />
												</li>
												{data.summary.services &&
													<>
														{data.summary.services.map((service, nth) => (
															<li className="services-service" key={nth}>
																<strong>{service.name}</strong>
																
																{service.price.original === 0 ?
																	<span className="label orange-bg uppercase small">Ücretsiz</span>
																	:
																	<Pricebox
																		className={service.price.original < 0 ? 'discount' : ''}
																		strike={service.price.original < 0}
																		price={service.price.original_str}
																		showCurrency />
																}
															</li>
														))}
													</>
												}
											</ul>

											<div className="payment-total">
												<strong>Toplam</strong>
												<Pricebox
													big
													showCurrency
													className="header-total"
													price={data.summary.grand_total.original_str} />
											</div>
										</div>
									</div>
								</div>

								<div className="content-agreements">
									<button
										type="button"
										onClick={() => {
											openModal(
												'agreement',
												{
													agreement: 'distance',
													parameter: data.sale.id
												}
											);
										}}
										className="agreements-agreement">
										Mesafeli Satış Sözleşmesi
									</button>
									<button
										type="button"
										onClick={() => {
											openModal(
												'agreement',
												{
													agreement: 'product_detail_return_text',
													source: 'sys'
													// parameter: data.sale.id
												}
											);
										}}
										className="agreements-agreement">
										Garanti ve İade Şartları
									</button>
								</div>
							</div>
						</div>

						<div className="checkout-section summary-products">
							<strong className="section-title">Ürünler</strong>

							{data.sale.products.length !== 0 ?
								<ul className="products-list">
									{data.sale.products.map((product, nth) => (
										<li className="list-product" key={`${nth}-${product.serial_id}`}>
											<Productbox
												type="list-summary"
												product={product} />
										</li>
									))}
								</ul>
								:
								<div className="basket-empty">
									<strong>Sepetinizde ürün bulunmuyor.</strong>
								</div>
							}
						</div>
					</section>
				</div>
			}
		</main>
	)
}

export default CheckoutSummary;