import '/assets/styles/partials/pricebox.scss';
import PropTypes from 'prop-types';
import { useMemo } from 'react';

// Functions
import formatNumber from '@wearetla/tla-essentials-tools/functions/format-number';

const PriceBox = ({ product, oldPrice: rawOldPrice, price: rawPrice, big, alignRight, strike, showCurrency, showTotal, ...props }) => {
	const { price, oldPrice } = useMemo(() => {
		var price = rawPrice;
		var oldPrice = rawOldPrice;

		if (product) {
			if (showTotal && product.total_price) {
				if (oldPrice !== false && product.total_price_before_campaigns && product.total_price_before_campaigns.original > product.total_price.original) {
					oldPrice = product.total_price_before_campaigns.original_str;
				}
	
				price = product.total_price.original_str;
			}
			else {
				if (oldPrice !== false && product.old_price && product.old_price.original > 0 && product.old_price.original > product.price.original) {
					oldPrice = product.old_price.original_str;
				}
				else {
					oldPrice = false;
				}
	
				price = product.price.original_str;
			}
		}
		else if(typeof price === 'number') {
			price = formatNumber(price);
		}

		return { price, oldPrice };
	}, [rawPrice, rawOldPrice, showTotal, product])

	return price ? (
		<span className={'pricebox ' + props.className + (big ? ' big' : '') + (alignRight ? ' align-right' : '') + (strike ? ' strike' : '')}>
			<span className="pricebox-top">
				{(product && alignRight && product.discount_ratio !== undefined && product.discount_ratio !== null && product.discount_ratio !== 0) &&
					<span className={'label pink top-discount' + (big ? '' : ' small')}>%{product.discount_ratio}{big ? ' indirim' : ''}</span>
				}
				<span className="top-price">{price}</span>
				{showCurrency &&
					<span className="top-currency">TL</span>
				}
				{(product && !alignRight && product.discount_ratio !== undefined && product.discount_ratio !== null && product.discount_ratio !== 0) &&
					<span className={'label pink top-discount' + (big ? '' : ' small')}>%{product.discount_ratio}{big ? ' indirim' : ''}</span>
				}
			</span>
			{oldPrice &&
				<span className="pricebox-old">{oldPrice} TL</span>
			}
		</span>
	) : false
}

PriceBox.defaultProps = {
	className: '',
	oldPrice: null,
	price: false,
	showTotal: false,
	alignRight: false,
	product: false,
	showCurrency: true,
	strike: false,
}

PriceBox.propTypes = {
	price: PropTypes.oneOfType([PropTypes.object, PropTypes.number, PropTypes.string, PropTypes.oneOf([false])]),
	product: PropTypes.oneOfType([PropTypes.oneOf([false]), PropTypes.object]),
	oldPrice: PropTypes.string,
	className: PropTypes.string,
	showTotal: PropTypes.bool,
	showCurrency: PropTypes.bool,
	strike: PropTypes.bool,
	alignRight: PropTypes.bool,
	big: PropTypes.bool,
}

export default PriceBox;